.bg-color[data-v-8aeca81c] {
  background: -webkit-gradient(linear, left top, right top, from(#ff5e1a), to(#ff8b20));
  background: linear-gradient(90deg, #ff5e1a, #ff8b20);
}
.f-color[data-v-8aeca81c] {
  color: #202c35;
  opacity: 0.5;
}
.circular-style[data-v-8aeca81c] {
  width: 6px;
  height: 6px;
  border-radius: 6px;
  margin-right: 6px;
  border: 1px solid #ff5e1a;
}
.failure-style[data-v-8aeca81c] .van-cell {
  background: transparent;
  padding: 10px 0;
}
.failure-style[data-v-8aeca81c] .van-cell::after {
  border-bottom: 0px;
}
.information-check[data-v-8aeca81c] {
  min-height: calc(100vh - 46px) !important;
}
.flex-center[data-v-8aeca81c] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.flex-j-center[data-v-8aeca81c] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.flex-a-center[data-v-8aeca81c] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.btn-ftooer[data-v-8aeca81c] {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  padding-bottom: env(safe-area-inset-bottom);
}
.btn-ftooer-s[data-v-8aeca81c] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin: 10px;
}
.bottom-btn[data-v-8aeca81c] {
  height: 40px;
  font-size: 13px;
  background: -webkit-gradient(linear, left top, right top, from(#ff5e1a), to(#ff8b20));
  background: linear-gradient(90deg, #ff5e1a, #ff8b20);
  border-radius: 20px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.bottom-btn[data-v-8aeca81c] {
  height: 40px;
  font-size: 13px;
  background: -webkit-gradient(linear, left top, right top, from(#ff5e1a), to(#ff8b20));
  background: linear-gradient(90deg, #ff5e1a, #ff8b20);
  border-radius: 20px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.bottom-btn-d[data-v-8aeca81c] {
  height: 40px;
  font-size: 13px;
  border-radius: 20px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.flex-between[data-v-8aeca81c] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.border-bgc[data-v-8aeca81c] {
  border: 1px solid rgba(255, 255, 255, 0.7);
  border-radius: 10px;
}
.fwbInfo[data-v-8aeca81c] {
  font-size: 14px;
  padding: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0px;
  background-color: #fafafa;
  border-radius: 10px;
}
.signed-page[data-v-8aeca81c] {
  width: 100vw;
  height: 100vh;
}
.signed-page .rowBox[data-v-8aeca81c] {
  width: 100%;
  height: 80vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.signed-page .rowBox .title[data-v-8aeca81c] {
  width: 100%;
  height: 100px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}