.signed-page[data-v-0fa8f62f] {
  width: 100vw;
  height: 100vh;
}
.signed-page .rowBox[data-v-0fa8f62f] {
  width: 100%;
  height: 80vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.signed-page .rowBox .title[data-v-0fa8f62f] {
  width: 100%;
  height: 100px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}