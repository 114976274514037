
.theme-message,
.theme-picker-dropdown {
    z-index: 99999 !important;
}
.theme-picker .el-color-picker__trigger {
    height: 26px !important;
    width: 26px !important;
    padding: 2px;
}
.theme-picker-dropdown .el-color-dropdown__link-btn {
    display: none;
}
