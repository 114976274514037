@charset "UTF-8";
.box[data-v-ace09d44] {
  width: 100%;
  margin: 0 auto;
  position: relative;
}

/* 重置样式也需要全局挂载 */
*[data-v-ace09d44] {
  margin: 0;
  padding: 0;
}
.course_node_video[data-v-ace09d44] {
  width: 100%;
  height: auto;
  margin: 0 auto;
  text-align: center;
  /* object-fit:fill; */
}

/* 是否禁止拖动进度条 */
/* .course_node_video >>> .vjs-progress-control {
        pointer-events: none;
      } */
/* 这里深度选择器这种生效,需要去掉style标签里面的scoped才可以生效使用,才可以铺满屏幕 */
[data-v-ace09d44] video {
  width: 100% !important;
  /* 我的容器高度设置的是100vh-95px，你们根据你们容器高度设置视频高度即可 */
  height: calc(100vh - 95px) !important;
  /* 消除两边留白 */
  -o-object-fit: fill;
     object-fit: fill;
}
.footer[data-v-ace09d44] {
  width: 100%;
  height: 50px;
  /* border: 1px solid red; */
  /* background-color: rgb(67,77,71); */
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.footer .list[data-v-ace09d44] {
  width: 15%;
  height: 100%;
  /* background-color: rgba(255, 255, 255, 0.1); */
  background-color: #434d47;
  border: 1px solid #e0d0d0;
  color: #fff;
  line-height: 2.8;
}
.videoMask[data-v-ace09d44] {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 98;
  /* display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center; */
  overflow: hidden;
}