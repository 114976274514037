.dashboard-editor-container[data-v-a361ec26] {
  padding: 10px 15px;
  background-color: #f5f5f5;
  position: relative;
  min-height: calc(100vh - 84px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.dashboard-editor-container .github-corner[data-v-a361ec26] {
  position: absolute;
  top: 0px;
  border: 0;
  right: 0;
}
.dashboard-editor-container .chart-wrapper[data-v-a361ec26] {
  background: #fff;
  padding: 16px 16px 0;
  margin-bottom: 32px;
}
@media (max-width: 1024px) {
.chart-wrapper[data-v-a361ec26] {
    padding: 8px;
}
}