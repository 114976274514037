.navbar[data-v-d16d6306] {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  -webkit-box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
          box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
}
.navbar .hamburger-container[data-v-d16d6306] {
  line-height: 46px;
  height: 100%;
  float: left;
  cursor: pointer;
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
  -webkit-tap-highlight-color: transparent;
}
.navbar .hamburger-container[data-v-d16d6306]:hover {
  background: rgba(0, 0, 0, 0.025);
}
.navbar .breadcrumb-container[data-v-d16d6306] {
  float: left;
}
.navbar .errLog-container[data-v-d16d6306] {
  display: inline-block;
  vertical-align: top;
}
.navbar .right-menu[data-v-d16d6306] {
  float: right;
  height: 100%;
  line-height: 50px;
}
.navbar .right-menu[data-v-d16d6306]:focus {
  outline: none;
}
.navbar .right-menu .right-menu-item[data-v-d16d6306] {
  display: inline-block;
  padding: 0 8px;
  height: 100%;
  font-size: 18px;
  color: #5a5e66;
  vertical-align: text-bottom;
}
.navbar .right-menu .right-menu-item.hover-effect[data-v-d16d6306] {
  cursor: pointer;
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
}
.navbar .right-menu .right-menu-item.hover-effect[data-v-d16d6306]:hover {
  background: rgba(0, 0, 0, 0.025);
}
.navbar .right-menu .avatar-container[data-v-d16d6306] {
  margin-right: 30px;
}
.navbar .right-menu .avatar-container .avatar-wrapper[data-v-d16d6306] {
  margin-top: 5px;
  position: relative;
}
.navbar .right-menu .avatar-container .avatar-wrapper .user-avatar[data-v-d16d6306] {
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 10px;
}
.navbar .right-menu .avatar-container .avatar-wrapper .el-icon-caret-bottom[data-v-d16d6306] {
  cursor: pointer;
  position: absolute;
  right: -20px;
  top: 25px;
  font-size: 12px;
}
.AnnounceDialog[data-v-d16d6306]  .el-dialog__body {
  padding: 20px;
}
.AnnounceDialog[data-v-d16d6306]  .el-dialog {
  border-radius: 20px;
  -webkit-box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.23);
          box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.23);
}
.msgDialog[data-v-d16d6306]  .el-dialog__body {
  padding: 20px;
}
.msgDialog[data-v-d16d6306]  .el-dialog__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  justify-items: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.msgDialog[data-v-d16d6306]  .el-dialog__headerbtn {
  width: 18px;
  height: 18px;
  position: static;
}
.msgDialog .dialog-footer[data-v-d16d6306] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.msgDialog .dialogFooterTitle[data-v-d16d6306] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.msgDialog[data-v-d16d6306]  .el-dialog {
  border-radius: 20px;
  -webkit-box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.23);
          box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.23);
  background: #f5f5f5;
}
.pageDialog[data-v-d16d6306]  .el-dialog__footer {
  background: #f5f5f5;
  height: 46px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.pageDialog[data-v-d16d6306]  .el-dialog__body {
  padding: 10px 10px 0px 10px;
  background: #f5f5f5;
}
.codeImgBox[data-v-d16d6306] {
  width: 40%;
  height: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-left: 5px;
  border: 1px solid #dcdfe6;
  overflow: hidden;
  border-radius: 4px;
}
.refreshButton[data-v-d16d6306] {
  width: 40px;
  height: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  border-left: 1px solid #dcdfe6;
}