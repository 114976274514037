.tags-view-wrapper .tags-view-item .el-icon-close {
  width: 16px;
  height: 16px;
  vertical-align: 2px;
  border-radius: 50%;
  text-align: center;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-transform-origin: 100% 50%;
          transform-origin: 100% 50%;
}
.tags-view-wrapper .tags-view-item .el-icon-close:before {
  -webkit-transform: scale(0.6);
          transform: scale(0.6);
  display: inline-block;
  vertical-align: -2px;
}
.tags-view-wrapper .tags-view-item .el-icon-close:hover {
  background-color: #b4bccc;
  color: #fff;
}