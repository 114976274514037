.wscn-http404-container[data-v-cb6dfc12] {
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  position: absolute;
  top: 40%;
  left: 50%;
}
.wscn-http404[data-v-cb6dfc12] {
  position: relative;
  width: 1200px;
  padding: 0 50px;
  overflow: hidden;
}
.wscn-http404 .pic-404[data-v-cb6dfc12] {
  position: relative;
  float: left;
  width: 600px;
  overflow: hidden;
}
.wscn-http404 .pic-404__parent[data-v-cb6dfc12] {
  width: 100%;
}
.wscn-http404 .pic-404__child[data-v-cb6dfc12] {
  position: absolute;
}
.wscn-http404 .pic-404__child.left[data-v-cb6dfc12] {
  width: 80px;
  top: 17px;
  left: 220px;
  opacity: 0;
  -webkit-animation-name: cloudLeft-data-v-cb6dfc12;
          animation-name: cloudLeft-data-v-cb6dfc12;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}
.wscn-http404 .pic-404__child.mid[data-v-cb6dfc12] {
  width: 46px;
  top: 10px;
  left: 420px;
  opacity: 0;
  -webkit-animation-name: cloudMid-data-v-cb6dfc12;
          animation-name: cloudMid-data-v-cb6dfc12;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 1.2s;
          animation-delay: 1.2s;
}
.wscn-http404 .pic-404__child.right[data-v-cb6dfc12] {
  width: 62px;
  top: 100px;
  left: 500px;
  opacity: 0;
  -webkit-animation-name: cloudRight-data-v-cb6dfc12;
          animation-name: cloudRight-data-v-cb6dfc12;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}
@-webkit-keyframes cloudLeft-data-v-cb6dfc12 {
0% {
    top: 17px;
    left: 220px;
    opacity: 0;
}
20% {
    top: 33px;
    left: 188px;
    opacity: 1;
}
80% {
    top: 81px;
    left: 92px;
    opacity: 1;
}
100% {
    top: 97px;
    left: 60px;
    opacity: 0;
}
}
@keyframes cloudLeft-data-v-cb6dfc12 {
0% {
    top: 17px;
    left: 220px;
    opacity: 0;
}
20% {
    top: 33px;
    left: 188px;
    opacity: 1;
}
80% {
    top: 81px;
    left: 92px;
    opacity: 1;
}
100% {
    top: 97px;
    left: 60px;
    opacity: 0;
}
}
@-webkit-keyframes cloudMid-data-v-cb6dfc12 {
0% {
    top: 10px;
    left: 420px;
    opacity: 0;
}
20% {
    top: 40px;
    left: 360px;
    opacity: 1;
}
70% {
    top: 130px;
    left: 180px;
    opacity: 1;
}
100% {
    top: 160px;
    left: 120px;
    opacity: 0;
}
}
@keyframes cloudMid-data-v-cb6dfc12 {
0% {
    top: 10px;
    left: 420px;
    opacity: 0;
}
20% {
    top: 40px;
    left: 360px;
    opacity: 1;
}
70% {
    top: 130px;
    left: 180px;
    opacity: 1;
}
100% {
    top: 160px;
    left: 120px;
    opacity: 0;
}
}
@-webkit-keyframes cloudRight-data-v-cb6dfc12 {
0% {
    top: 100px;
    left: 500px;
    opacity: 0;
}
20% {
    top: 120px;
    left: 460px;
    opacity: 1;
}
80% {
    top: 180px;
    left: 340px;
    opacity: 1;
}
100% {
    top: 200px;
    left: 300px;
    opacity: 0;
}
}
@keyframes cloudRight-data-v-cb6dfc12 {
0% {
    top: 100px;
    left: 500px;
    opacity: 0;
}
20% {
    top: 120px;
    left: 460px;
    opacity: 1;
}
80% {
    top: 180px;
    left: 340px;
    opacity: 1;
}
100% {
    top: 200px;
    left: 300px;
    opacity: 0;
}
}
.wscn-http404 .bullshit[data-v-cb6dfc12] {
  position: relative;
  float: left;
  width: 300px;
  padding: 30px 0;
  overflow: hidden;
}
.wscn-http404 .bullshit__oops[data-v-cb6dfc12] {
  font-size: 32px;
  font-weight: bold;
  line-height: 40px;
  color: #1482f0;
  opacity: 0;
  margin-bottom: 20px;
  -webkit-animation-name: slideUp-data-v-cb6dfc12;
          animation-name: slideUp-data-v-cb6dfc12;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
.wscn-http404 .bullshit__headline[data-v-cb6dfc12] {
  font-size: 20px;
  line-height: 24px;
  color: #222;
  font-weight: bold;
  opacity: 0;
  margin-bottom: 10px;
  -webkit-animation-name: slideUp-data-v-cb6dfc12;
          animation-name: slideUp-data-v-cb6dfc12;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
.wscn-http404 .bullshit__info[data-v-cb6dfc12] {
  font-size: 13px;
  line-height: 21px;
  color: grey;
  opacity: 0;
  margin-bottom: 30px;
  -webkit-animation-name: slideUp-data-v-cb6dfc12;
          animation-name: slideUp-data-v-cb6dfc12;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
.wscn-http404 .bullshit__return-home[data-v-cb6dfc12] {
  display: block;
  float: left;
  width: 110px;
  height: 36px;
  background: #1482f0;
  border-radius: 100px;
  text-align: center;
  color: #ffffff;
  opacity: 0;
  font-size: 14px;
  line-height: 36px;
  cursor: pointer;
  -webkit-animation-name: slideUp-data-v-cb6dfc12;
          animation-name: slideUp-data-v-cb6dfc12;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
@-webkit-keyframes slideUp-data-v-cb6dfc12 {
0% {
    -webkit-transform: translateY(60px);
            transform: translateY(60px);
    opacity: 0;
}
100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
}
}
@keyframes slideUp-data-v-cb6dfc12 {
0% {
    -webkit-transform: translateY(60px);
            transform: translateY(60px);
    opacity: 0;
}
100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
}
}