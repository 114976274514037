.drawer-container[data-v-126b135a] {
  padding: 24px;
  font-size: 14px;
  line-height: 1.5;
  word-wrap: break-word;
}
.drawer-container .drawer-title[data-v-126b135a] {
  margin-bottom: 12px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 22px;
}
.drawer-container .drawer-item[data-v-126b135a] {
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  padding: 12px 0;
}
.drawer-container .drawer-switch[data-v-126b135a] {
  float: right;
}