.app-cls[data-v-0c555b29] {
  padding-top: 1px;
  background: #f5f5f5;
  padding-bottom: 10px;
}
.app-cls[data-v-0c555b29]  .el-table {
  color: #333333;
  font-size: 13px;
}
.app-cls[data-v-0c555b29]  label {
  font-weight: 500;
  font-size: 13px;
}
.app-cls[data-v-0c555b29]  .el-button + .el-button {
  margin-left: 5px;
}
.app-cls[data-v-0c555b29]  .el-card__body {
  padding: 10px;
}
.app-cls[data-v-0c555b29] .el-col {
  min-height: 46px;
}
.app-cls[data-v-0c555b29]  .el-table--small td {
  padding: 8px 0;
}
.app-cls .normal-btn-cls[data-v-0c555b29]  .el-table--small td {
  padding: 5px 0;
}
.app-cls[data-v-0c555b29]  .el-card__header {
  padding: 8px 10px;
}
.app-cls .form-item-cls[data-v-0c555b29] {
  width: 100%;
  max-width: 320px;
}
.app-cls .header-cls[data-v-0c555b29] {
  margin: 10px 15px 0px 15px;
  background: #f5f5f5;
}
.app-cls .table-cls[data-v-0c555b29] {
  margin: 10px 15px 0px 15px;
  background: #f5f5f5;
}
.app-cls .table-cls[data-v-0c555b29]  .el-table__body tr.current-row > td {
  background: #edf5ff !important;
}
.app-cls .table-cls[data-v-0c555b29]  .el-table td {
  border-bottom: 1px solid #e9e9e9;
}
.app-cls .table-cls[data-v-0c555b29]  thead .cell {
  color: #333333;
}
.app-cls .table-cls[data-v-0c555b29]  .el-table__row .float-action-wrp {
  display: none;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
  -webkit-transition: right, 0.2s;
  transition: right, 0.2s;
}
.app-cls .table-cls[data-v-0c555b29]  .el-table__row .float-action-wrp .float-action {
  display: block;
  padding: 0 6px 5px 6px;
  border-left: 1px solid #ebeef5;
  border-bottom: 1px solid #ebeef5;
  border-radius: 0 0 0 2px;
  white-space: nowrap;
  position: absolute;
  right: 0;
  bottom: -34px;
  background-color: #fff;
}
.app-cls .table-cls[data-v-0c555b29]  .el-table__row .float-action-wrp .float-action .el-button + .el-button {
  margin-left: 5px;
}
.app-cls .table-cls[data-v-0c555b29]  .el-table__row:hover td .float-action-wrp {
  display: block;
}
.app-cls .table-cls[data-v-0c555b29]  .el-table__row:hover td .float-action-wrp .float-action {
  background-color: #f5f7fa;
  border-left: 1px solid #ebeef5;
  border-bottom: 1px solid #ebeef5;
}
.app-cls .table-cls[data-v-0c555b29]  .el-table__row:last-child:not(:first-child) td .float-action-wrp .float-action {
  padding: 5px 6px 0 6px;
  top: -34px;
  bottom: auto;
  border-top: 1px solid #ebeef5;
  border-bottom: 0;
  border-radius: 2px 0 0 0;
}
.seachComStyle[data-v-0c555b29] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.seachComStyle > div:first-child span[data-v-0c555b29]:not(:first-child) {
  margin-left: 8px;
}
.seachComStyle > div:first-child span[data-v-0c555b29] {
  font-size: 13px;
}
.seachComStyle > div[data-v-0c555b29]:first-child .el-input,
.seachComStyle > div[data-v-0c555b29]:first-child .el-select {
  max-width: 165px;
}
.seachComStyle > div:first-child button[data-v-0c555b29] {
  margin-left: 8px;
}
.codeImgBox[data-v-0c555b29] {
  width: 40%;
  height: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-left: 5px;
  border: 1px solid #dcdfe6;
  overflow: hidden;
  border-radius: 4px;
}
.refreshButton[data-v-0c555b29] {
  width: 40px;
  height: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  border-left: 1px solid #dcdfe6;
}
.el-step[data-v-0c555b29] {
  margin: 50px 0 100px;
}
.el-step[data-v-0c555b29]  .el-step__title {
  font-size: 14px;
}