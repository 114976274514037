
.hamburger[data-v-4e6f274c] {
	display: inline-block;
	vertical-align: middle;
	width: 20px;
	height: 20px;
}
.hamburger.is-active[data-v-4e6f274c] {
	-webkit-transform: rotate(180deg);
	        transform: rotate(180deg);
}
