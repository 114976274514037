@charset "UTF-8";
*[data-v-e20848ae] {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
html[data-v-e20848ae] {
  background-color: #f9f9f9;
  font-family: "微软雅黑";
}
body[data-v-e20848ae] {
  font-family: "微软雅黑";
}
#app[data-v-e20848ae] {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
}
.text-gray[data-v-e20848ae] {
  color: #969799;
}
.text-left[data-v-e20848ae] {
  text-align: left;
}
.common-padding-left[data-v-e20848ae] {
  padding-left: 16px;
}
.btn-ftooer[data-v-e20848ae] {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  z-index: 99;
  padding-bottom: env(safe-area-inset-bottom);
}
.color-red[data-v-e20848ae] {
  color: #ca032a;
}
.btn-ftooer-s[data-v-e20848ae] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 10px;
  border: 1px solid #fff;
}
.bottom-btn[data-v-e20848ae] {
  height: 40px;
  font-size: 14px;
  background: #559AF7;
  color: #fff;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  border-radius: 20px;
}
.bottom-btn-plain[data-v-e20848ae] {
  height: 40px;
  font-size: 14px;
  color: #559AF7;
  border: 1px solid #559AF7;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  border-radius: 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.bottom-btn-d[data-v-e20848ae] {
  height: 40px;
  font-size: 14px;
  border-radius: 20px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.common-btn[data-v-e20848ae] {
  height: 30px;
  font-size: 14px;
  background-color: #ca032a;
  border-color: #ca032a;
  width: 70px;
  border-radius: 8px;
}
.nav-top-style[data-v-e20848ae] {
  padding-top: constant(safe-area-inset-top);
  /* 兼容 iOS<11.2 */
  padding-top: env(safe-area-inset-top);
  /* 兼容iOS>= 11.2 */
  background: #f3f5f6;
}
.nav-top-style[data-v-e20848ae]  .van-icon-ellipsis {
  color: black;
}
.nav-top-style[data-v-e20848ae]  .van-ellipsis {
  color: black;
  font-weight: 600;
}
.nav-top-style[data-v-e20848ae]  .van-icon-arrow-left {
  color: black;
}
.car-search.van-search[data-v-e20848ae] {
  background-color: #ca032a;
}
.car-search.van-search .van-search__action[data-v-e20848ae] {
  color: #FFF;
}
.new-car[data-v-e20848ae] {
  background-color: #f9f9f9;
}
.new-car.van-switch--on[data-v-e20848ae] {
  background-color: #ca032a;
}
.home-title[data-v-e20848ae] {
  background-color: #ca032a;
  padding: 1px;
  color: #fff;
}
.home-pint[data-v-e20848ae] {
  background-color: #ca032a;
  padding: 10px 20px;
  font-size: 14px;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.van-picker__toolbar .van-picker__cancel[data-v-e20848ae] {
  color: #ca032a;
}
.van-picker__toolbar .van-picker__confirm[data-v-e20848ae] {
  color: #ca032a;
}
.van-dropdown-menu__title--active[data-v-e20848ae] {
  color: #ca032a;
}
.van-dropdown-item__option--active[data-v-e20848ae] {
  color: #ca032a;
}
.van-dropdown-item__option--active .van-dropdown-item__icon[data-v-e20848ae] {
  color: #ca032a;
}
.van-nav-bar .van-icon[data-v-e20848ae] {
  color: #000;
}
.van-checkbox__icon--checked .van-icon[data-v-e20848ae] {
  background-color: #ca032a;
  border-color: #ca032a;
}
.van-nav-bar__text[data-v-e20848ae] {
  color: #ca032a;
}
[data-v-e20848ae] .van-field__error-message {
  display: none;
}
.flex-j-center[data-v-e20848ae] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.flex-a-center[data-v-e20848ae] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.flex-center[data-v-e20848ae] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.flex-between[data-v-e20848ae] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.margin-right-15[data-v-e20848ae] {
  margin-right: 15px;
}
.margin-right-30[data-v-e20848ae] {
  margin-right: 30px;
}
.margin-left-15[data-v-e20848ae] {
  margin-left: 15px;
}
.margin-left-30[data-v-e20848ae] {
  margin-left: 30px;
}
.title-style-pint[data-v-e20848ae] {
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  display: block;
}
.title-style-pint-s[data-v-e20848ae] {
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  display: block;
}
.word-content[data-v-e20848ae] {
  padding: 15px;
  font-size: 13px;
  text-align: left;
}
.car_model_style .van-field__body[data-v-e20848ae] {
  width: 90%;
}
.page-bgc[data-v-e20848ae] {
  background-color: #f3f5f6;
  min-height: calc(100vh - 46px);
  width: 100%;
}
.page-bgc .page-box[data-v-e20848ae] {
  margin-top: 46px;
  padding: 0 10px 100px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.wrap-bottoms[data-v-e20848ae] {
  padding-bottom: env(safe-area-inset-bottom);
}
.picker-toolbar-fiexd[data-v-e20848ae] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}
.picker-toolbar-fiexd .picker-toolbar-fiexd-cancel[data-v-e20848ae] {
  color: #559AF7;
  width: 49.5%;
  text-align: center;
  line-height: 46px;
  border-right: 1px solid #eee;
}
.picker-toolbar-fiexd .picker-toolbar-fiexd-confirm[data-v-e20848ae] {
  color: #559AF7;
  width: 49.5%;
  text-align: center;
  line-height: 46px;
}
.van-nav-bar[data-v-e20848ae] {
  z-index: 100;
}
.subject-bg-color[data-v-e20848ae] {
  background-color: #559AF7;
}
.mr10[data-v-e20848ae] {
  margin-right: 10px;
}
.mb100[data-v-e20848ae] {
  margin-bottom: 100px;
}
.empty-list-box[data-v-e20848ae] {
  padding: 110px 0 50px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.loading-box[data-v-e20848ae] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 99;
}
.van-popup80[data-v-e20848ae] {
  width: 80%;
  height: 100%;
  overflow-y: initial;
}
.van-popup80 .bottom-btn-reset[data-v-e20848ae] {
  font-size: 14px;
  background: #fafafa;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.van-popup80 .bottom-btn-save[data-v-e20848ae] {
  font-size: 14px;
  background: #559AF7;
  color: #fff;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.ptb10[data-v-e20848ae] {
  padding: 10px 0;
}
.new-bottom-btn[data-v-e20848ae] {
  height: 40px;
  font-size: 14px;
  background: #559AF7;
  color: #fff;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  border-radius: 8px;
}
.mt10[data-v-e20848ae] {
  margin-top: 10px;
}
.mb10[data-v-e20848ae] {
  margin-bottom: 10px;
}
.mtb10[data-v-e20848ae] {
  margin: 10px 0;
}
.mtr10[data-v-e20848ae] {
  margin: 10px 10px 0 0;
}
.ml10[data-v-e20848ae] {
  margin-left: 10px;
}
.mb20[data-v-e20848ae] {
  margin-bottom: 20px;
}
.flex-style[data-v-e20848ae] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.container[data-v-e20848ae] {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto !important;
  height: auto !important;
  overflow: hidden !important;
}
[data-v-e20848ae] .pdfjs .pdfViewer .pageContainer {
  width: 95vw;
  height: 77vh;
}
[data-v-e20848ae] .pdfjs .pdfViewer .pageContainer img {
  height: auto;
}