.box-center[data-v-218e8838] {
  margin: 0 auto;
  display: table;
}
.text-muted[data-v-218e8838] {
  color: #777;
}
.user-profile .user-name[data-v-218e8838] {
  font-weight: bold;
}
.user-profile .box-center[data-v-218e8838] {
  padding-top: 10px;
}
.user-profile .user-role[data-v-218e8838] {
  padding-top: 10px;
  font-weight: 400;
  font-size: 14px;
}
.user-profile .box-social[data-v-218e8838] {
  padding-top: 30px;
}
.user-profile .box-social .el-table[data-v-218e8838] {
  border-top: 1px solid #dfe6ec;
}
.user-profile .user-follow[data-v-218e8838] {
  padding-top: 20px;
}
.user-bio[data-v-218e8838] {
  margin-top: 20px;
  color: #606266;
}
.user-bio span[data-v-218e8838] {
  padding-left: 4px;
}
.user-bio .user-bio-section[data-v-218e8838] {
  font-size: 14px;
  padding: 15px 0;
}
.user-bio .user-bio-section .user-bio-section-header[data-v-218e8838] {
  border-bottom: 1px solid #dfe6ec;
  padding-bottom: 10px;
  margin-bottom: 10px;
  font-weight: bold;
}