.sign[data-v-5b78718e] {
  position: relative;
  padding: 20px 0 40px;
}
.sign .signature-placeholder[data-v-5b78718e] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -100%);
          transform: translate(-50%, -100%);
  color: rgba(0, 0, 0, 0.1);
  font-size: 50px;
}
.canvas-btns[data-v-5b78718e] {
  width: 90%;
  padding: 10px 0;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.tip[data-v-5b78718e] {
  font-size: 14px;
  color: #999;
}
.canvas-box[data-v-5b78718e] {
  width: 100%;
}
#canvas[data-v-5b78718e] {
  border: 1px dashed #d9d9d9;
  margin: 0 auto;
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  z-index: 999;
}
.btn[data-v-5b78718e] {
  position: relative;
  width: 90%;
  margin: 30px auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}