.errPage-container[data-v-cbc28918] {
  width: 800px;
  max-width: 100%;
  margin: 100px auto;
}
.errPage-container .pan-back-btn[data-v-cbc28918] {
  background: #008489;
  color: #fff;
  border: none !important;
}
.errPage-container .pan-gif[data-v-cbc28918] {
  margin: 0 auto;
  display: block;
}
.errPage-container .pan-img[data-v-cbc28918] {
  display: block;
  margin: 0 auto;
  width: 100%;
}
.errPage-container .text-jumbo[data-v-cbc28918] {
  font-size: 60px;
  font-weight: 700;
  color: #484848;
}
.errPage-container .list-unstyled[data-v-cbc28918] {
  font-size: 14px;
}
.errPage-container .list-unstyled li[data-v-cbc28918] {
  padding-bottom: 5px;
}
.errPage-container .list-unstyled a[data-v-cbc28918] {
  color: #008489;
  text-decoration: none;
}
.errPage-container .list-unstyled a[data-v-cbc28918]:hover {
  text-decoration: underline;
}