.login-container[data-v-37dfd6fc] {
  width: 60%;
  min-width: 580px;
  max-width: 960px;
  position: absolute;
  top: 25%;
}
.login-container .login-form[data-v-37dfd6fc] {
  width: 380px;
  max-width: 100%;
  padding: 25px 35px;
  margin: 0 auto;
  overflow: hidden;
}
.login-container .tips[data-v-37dfd6fc] {
  font-size: 14px;
  color: #fff;
  margin-bottom: 10px;
}
.login-container .tips span[data-v-37dfd6fc]:first-of-type {
  margin-right: 16px;
}
.login-container .svg-container[data-v-37dfd6fc] {
  padding: 6px 5px 6px 15px;
  color: #999;
  vertical-align: middle;
  width: 30px;
  display: inline-block;
}
.login-container .title-container[data-v-37dfd6fc] {
  margin-top: 10px;
  text-align: center;
  min-height: 140px;
  max-height: 160px;
}
.login-container .title-container .title[data-v-37dfd6fc] {
  font-size: 20px;
  color: #555;
  margin: 0px auto 60px auto;
  text-align: center;
}
.login-container .show-pwd[data-v-37dfd6fc] {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: absolute;
  right: 12px;
  top: 1px;
  font-size: 16px;
  color: #999;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.mainContainer[data-v-37dfd6fc] {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.bgImgContainer[data-v-37dfd6fc] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  height: 100vh;
}
.right[data-v-37dfd6fc] {
  width: 0px;
  height: 0px;
}
.account-application[data-v-37dfd6fc] {
  color: #2150A3;
  font-size: 14px;
  float: right;
  cursor: pointer;
}
.codeImgBox[data-v-37dfd6fc] {
  width: 40%;
  height: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-left: 5px;
  border: 1px solid #dcdfe6;
  overflow: hidden;
  border-radius: 4px;
}
.refreshButton[data-v-37dfd6fc] {
  width: 40px;
  height: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  border-left: 1px solid #dcdfe6;
}
.pageDialog[data-v-37dfd6fc] {
  margin-top: -5vh;
}
.pageDialog[data-v-37dfd6fc]  .el-dialog__footer {
  background: #f5f5f5;
  height: 46px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.pageDialog[data-v-37dfd6fc]  .el-dialog__body {
  padding: 10px;
  background: #f5f5f5;
}