[data-v-fac8ca64]:export {
  menuText: #bfcbd9;
  menuActiveText: #409EFF;
  subMenuActiveText: #f4f4f5;
  menuBg: #304156;
  menuHover: #263445;
  subMenuBg: #1f2d3d;
  subMenuHover: #001528;
  sideBarWidth: 210px;
}
.tags-view-container[data-v-fac8ca64] {
  height: 34px;
  width: 100%;
  background: #fff;
  border-bottom: 1px solid #d8dce5;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 0 3px 0 rgba(0, 0, 0, 0.04);
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 0 3px 0 rgba(0, 0, 0, 0.04);
}
.tags-view-container .tags-view-wrapper .tags-view-item[data-v-fac8ca64] {
  display: inline-block;
  position: relative;
  cursor: pointer;
  height: 26px;
  line-height: 26px;
  border: 1px solid #ddd;
  border-radius: 5px 5px 0px 0px;
  color: #495060;
  background: #fff;
  padding: 0 8px;
  font-size: 12px;
  margin-left: 2px;
  margin-top: 4px;
}
.tags-view-container .tags-view-wrapper .tags-view-item[data-v-fac8ca64]:first-of-type {
  margin-left: 15px;
}
.tags-view-container .tags-view-wrapper .tags-view-item[data-v-fac8ca64]:last-of-type {
  margin-right: 15px;
}
.tags-view-container .tags-view-wrapper .tags-view-item.active[data-v-fac8ca64] {
  background-color: #3A71A8;
  color: #fff;
  border-color: #3A71A8;
  border-radius: 5px 5px 0px 0px;
}
.tags-view-container .tags-view-wrapper .tags-view-item.active[data-v-fac8ca64]::before {
  content: "";
  background: #fff;
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: relative;
  margin-right: 1px;
}
.tags-view-container .contextmenu[data-v-fac8ca64] {
  margin: 0;
  background: #fff;
  z-index: 3000;
  position: absolute;
  list-style-type: none;
  padding: 5px 0;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #333;
  -webkit-box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);
          box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);
}
.tags-view-container .contextmenu li[data-v-fac8ca64] {
  margin: 0;
  padding: 7px 16px;
  cursor: pointer;
}
.tags-view-container .contextmenu li[data-v-fac8ca64]:hover {
  background: #eee;
}