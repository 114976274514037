
.github-corner:hover .octo-arm[data-v-402c866a] {
  -webkit-animation: octocat-wave-data-v-402c866a 560ms ease-in-out;
          animation: octocat-wave-data-v-402c866a 560ms ease-in-out
}
@-webkit-keyframes octocat-wave-data-v-402c866a {
0%,
  100% {
    -webkit-transform: rotate(0);
            transform: rotate(0)
}
20%,
  60% {
    -webkit-transform: rotate(-25deg);
            transform: rotate(-25deg)
}
40%,
  80% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg)
}
}
@keyframes octocat-wave-data-v-402c866a {
0%,
  100% {
    -webkit-transform: rotate(0);
            transform: rotate(0)
}
20%,
  60% {
    -webkit-transform: rotate(-25deg);
            transform: rotate(-25deg)
}
40%,
  80% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg)
}
}
@media (max-width:500px) {
.github-corner:hover .octo-arm[data-v-402c866a] {
    -webkit-animation: none;
            animation: none
}
.github-corner .octo-arm[data-v-402c866a] {
    -webkit-animation: octocat-wave-data-v-402c866a 560ms ease-in-out;
            animation: octocat-wave-data-v-402c866a 560ms ease-in-out
}
}
