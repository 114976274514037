[data-v-13877386]:export {
  menuText: #bfcbd9;
  menuActiveText: #409EFF;
  subMenuActiveText: #f4f4f5;
  menuBg: #304156;
  menuHover: #263445;
  subMenuBg: #1f2d3d;
  subMenuHover: #001528;
  sideBarWidth: 210px;
}
.app-wrapper[data-v-13877386] {
  position: relative;
  height: 100%;
  width: 100%;
}
.app-wrapper[data-v-13877386]:after {
  content: "";
  display: table;
  clear: both;
}
.app-wrapper.mobile.openSidebar[data-v-13877386] {
  position: fixed;
  top: 0;
}
.drawer-bg[data-v-13877386] {
  background: #000;
  opacity: 0.3;
  width: 100%;
  top: 0;
  height: 100%;
  position: absolute;
  z-index: 999;
}
.fixed-header[data-v-13877386] {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9;
  width: calc(100% - 210px);
  -webkit-transition: width 0.28s;
  transition: width 0.28s;
}
.hideSidebar .fixed-header[data-v-13877386] {
  width: calc(100% - 54px);
}
.mobile .fixed-header[data-v-13877386] {
  width: 100%;
}