.user-activity .user-block .username[data-v-7e91c778],
.user-activity .user-block .description[data-v-7e91c778] {
  display: block;
  margin-left: 50px;
  padding: 2px 0;
}
.user-activity .user-block .username[data-v-7e91c778] {
  font-size: 16px;
  color: #000;
}
.user-activity .user-block[data-v-7e91c778] :after {
  clear: both;
}
.user-activity .user-block .img-circle[data-v-7e91c778] {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  float: left;
}
.user-activity .user-block span[data-v-7e91c778] {
  font-weight: 500;
  font-size: 12px;
}
.user-activity .post[data-v-7e91c778] {
  font-size: 14px;
  border-bottom: 1px solid #d2d6de;
  margin-bottom: 15px;
  padding-bottom: 15px;
  color: #666;
}
.user-activity .post .image[data-v-7e91c778] {
  width: 100%;
  height: 100%;
}
.user-activity .post .user-images[data-v-7e91c778] {
  padding-top: 20px;
}
.user-activity .list-inline[data-v-7e91c778] {
  padding-left: 0;
  margin-left: -5px;
  list-style: none;
}
.user-activity .list-inline li[data-v-7e91c778] {
  display: inline-block;
  padding-right: 5px;
  padding-left: 5px;
  font-size: 13px;
}
.user-activity .list-inline .link-black[data-v-7e91c778]:hover, .user-activity .list-inline .link-black[data-v-7e91c778]:focus {
  color: #999;
}
.box-center[data-v-7e91c778] {
  margin: 0 auto;
  display: table;
}
.text-muted[data-v-7e91c778] {
  color: #777;
}