@charset "UTF-8";
.app-cls[data-v-e9fc4a28] {
  padding-top: 1px;
  background: #f5f5f5;
  padding-bottom: 10px;
}
.app-cls[data-v-e9fc4a28]  .el-table {
  color: #333333;
  font-size: 13px;
}
.app-cls[data-v-e9fc4a28]  label {
  font-weight: 500;
  font-size: 13px;
}
.app-cls[data-v-e9fc4a28]  .el-button + .el-button {
  margin-left: 5px;
}
.app-cls[data-v-e9fc4a28]  .el-card__body {
  padding: 10px;
}
.app-cls[data-v-e9fc4a28] .el-col {
  min-height: 46px;
}
.app-cls[data-v-e9fc4a28]  .el-table--small td {
  padding: 8px 0;
}
.app-cls .normal-btn-cls[data-v-e9fc4a28]  .el-table--small td {
  padding: 5px 0;
}
.app-cls[data-v-e9fc4a28]  .el-card__header {
  padding: 8px 10px;
}
.app-cls .form-item-cls[data-v-e9fc4a28] {
  width: 100%;
  max-width: 320px;
}
.app-cls .header-cls[data-v-e9fc4a28] {
  margin: 10px 15px 0px 15px;
  background: #f5f5f5;
}
.app-cls .table-cls[data-v-e9fc4a28] {
  margin: 10px 15px 0px 15px;
  background: #f5f5f5;
}
.app-cls .table-cls[data-v-e9fc4a28]  .el-table__body tr.current-row > td {
  background: #edf5ff !important;
}
.app-cls .table-cls[data-v-e9fc4a28]  .el-table td {
  border-bottom: 1px solid #e9e9e9;
}
.app-cls .table-cls[data-v-e9fc4a28]  thead .cell {
  color: #333333;
}
.app-cls .table-cls[data-v-e9fc4a28]  .el-table__row .float-action-wrp {
  display: none;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
  -webkit-transition: right, 0.2s;
  transition: right, 0.2s;
}
.app-cls .table-cls[data-v-e9fc4a28]  .el-table__row .float-action-wrp .float-action {
  display: block;
  padding: 0 6px 5px 6px;
  border-left: 1px solid #ebeef5;
  border-bottom: 1px solid #ebeef5;
  border-radius: 0 0 0 2px;
  white-space: nowrap;
  position: absolute;
  right: 0;
  bottom: -34px;
  background-color: #fff;
}
.app-cls .table-cls[data-v-e9fc4a28]  .el-table__row .float-action-wrp .float-action .el-button + .el-button {
  margin-left: 5px;
}
.app-cls .table-cls[data-v-e9fc4a28]  .el-table__row:hover td .float-action-wrp {
  display: block;
}
.app-cls .table-cls[data-v-e9fc4a28]  .el-table__row:hover td .float-action-wrp .float-action {
  background-color: #f5f7fa;
  border-left: 1px solid #ebeef5;
  border-bottom: 1px solid #ebeef5;
}
.app-cls .table-cls[data-v-e9fc4a28]  .el-table__row:last-child:not(:first-child) td .float-action-wrp .float-action {
  padding: 5px 6px 0 6px;
  top: -34px;
  bottom: auto;
  border-top: 1px solid #ebeef5;
  border-bottom: 0;
  border-radius: 2px 0 0 0;
}
.seachComStyle[data-v-e9fc4a28] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.seachComStyle > div:first-child span[data-v-e9fc4a28]:not(:first-child) {
  margin-left: 8px;
}
.seachComStyle > div:first-child span[data-v-e9fc4a28] {
  font-size: 13px;
}
.seachComStyle > div[data-v-e9fc4a28]:first-child .el-input,
.seachComStyle > div[data-v-e9fc4a28]:first-child .el-select {
  max-width: 165px;
}
.seachComStyle > div:first-child button[data-v-e9fc4a28] {
  margin-left: 8px;
}
[data-v-e9fc4a28] .el-card__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.videoDetail[data-v-e9fc4a28] {
  width: 100%;
  margin-top: 10px;
}
.title[data-v-e9fc4a28] {
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  color: #333;
}
.desc[data-v-e9fc4a28] {
  width: 100%;
  font-size: 12px;
  color: #999;
  margin-top: 6px;
}
.video-player[data-v-e9fc4a28] {
  width: 100%;
  max-height: 500px;
  margin: 0 auto;
  text-align: center;
  /* object-fit:fill; */
}

/* 是否禁止拖动进度条 */
/* .course_node_video >>> .vjs-progress-control {
        pointer-events: none;
      } */
[data-v-e9fc4a28] .video-player .video-js {
  width: auto !important;
  height: 500px !important;
  margin: 0 auto !important;
}
[data-v-e9fc4a28] .video-js .vjs-tech {
  position: absolute;
  top: 0;
  left: 50%;
  width: auto !important;
  height: 100%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

/* 这里深度选择器这种生效,需要去掉style标签里面的scoped才可以生效使用,才可以铺满屏幕 */
[data-v-e9fc4a28] video {
  max-width: 100% !important;
  /* 我的容器高度设置的是100vh-95px，你们根据你们容器高度设置视频高度即可 */
  max-height: calc(100vh - 65px) !important;
  /* 消除两边留白 */
  -o-object-fit: fill;
     object-fit: fill;
  margin: 0 auto;
}