.emptyGif[data-v-4dfc1336] {
  display: block;
  width: 45%;
  margin: 0 auto;
}
.dashboard-editor-container[data-v-4dfc1336] {
  background-color: #e3e3e3;
  min-height: 100vh;
  padding: 50px 60px 0px;
}
.dashboard-editor-container .pan-info-roles[data-v-4dfc1336] {
  font-size: 12px;
  font-weight: 700;
  color: #333;
  display: block;
}
.dashboard-editor-container .info-container[data-v-4dfc1336] {
  position: relative;
  margin-left: 190px;
  height: 150px;
  line-height: 200px;
}
.dashboard-editor-container .info-container .display_name[data-v-4dfc1336] {
  font-size: 48px;
  line-height: 48px;
  color: #212121;
  position: absolute;
  top: 25px;
}