
.notify-icon-info {
    color: #909399;
    font-size: 24px;
}
.notify-icon-warning {
    color: #e6a23c;
    font-size: 24px;
}
.notify-icon-success {
    color: #67c23a;
    font-size: 24px;
}
.notify-icon-error {
    color: #f56c6c;
    font-size: 24px;
}
