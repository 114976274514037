
.pan-item[data-v-175fbaac] {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  cursor: default;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}
.pan-info-roles-container[data-v-175fbaac] {
  padding: 20px;
  text-align: center;
}
.pan-thumb[data-v-175fbaac] {
  width: 100%;
  height: 100%;
  background-size: 100%;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  -webkit-transform-origin: 95% 40%;
          transform-origin: 95% 40%;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.pan-thumb[data-v-175fbaac]:after {
  content: '';
  width: 8px;
  height: 8px;
  position: absolute;
  border-radius: 50%;
  top: 40%;
  left: 95%;
  margin: -4px 0 0 -4px;
  background: radial-gradient(ellipse at center, rgba(14, 14, 14, 1) 0%, rgba(125, 126, 125, 1) 100%);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.9);
          box-shadow: 0 0 1px rgba(255, 255, 255, 0.9);
}
.pan-info[data-v-175fbaac] {
  position: absolute;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  overflow: hidden;
  -webkit-box-shadow: inset 0 0 0 5px rgba(0, 0, 0, 0.05);
          box-shadow: inset 0 0 0 5px rgba(0, 0, 0, 0.05);
}
.pan-info h3[data-v-175fbaac] {
  color: #fff;
  text-transform: uppercase;
  position: relative;
  letter-spacing: 2px;
  font-size: 18px;
  margin: 0 60px;
  padding: 22px 0 0 0;
  height: 85px;
  font-family: 'Open Sans', Arial, sans-serif;
  text-shadow: 0 0 1px #fff, 0 1px 2px rgba(0, 0, 0, 0.3);
}
.pan-info p[data-v-175fbaac] {
  color: #fff;
  padding: 10px 5px;
  font-style: italic;
  margin: 0 30px;
  font-size: 12px;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
}
.pan-info p a[data-v-175fbaac] {
  display: block;
  color: #333;
  width: 80px;
  height: 80px;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  color: #fff;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 9px;
  letter-spacing: 1px;
  padding-top: 24px;
  margin: 7px auto 0;
  font-family: 'Open Sans', Arial, sans-serif;
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-in-out 0.2s, background 0.2s linear 0s, -webkit-transform 0.3s ease-in-out 0.2s;
  transition: opacity 0.3s ease-in-out 0.2s, background 0.2s linear 0s, -webkit-transform 0.3s ease-in-out 0.2s;
  transition: transform 0.3s ease-in-out 0.2s, opacity 0.3s ease-in-out 0.2s, background 0.2s linear 0s;
  transition: transform 0.3s ease-in-out 0.2s, opacity 0.3s ease-in-out 0.2s, background 0.2s linear 0s, -webkit-transform 0.3s ease-in-out 0.2s;
  -webkit-transform: translateX(60px) rotate(90deg);
          transform: translateX(60px) rotate(90deg);
}
.pan-info p a[data-v-175fbaac]:hover {
  background: rgba(255, 255, 255, 0.5);
}
.pan-item:hover .pan-thumb[data-v-175fbaac] {
  -webkit-transform: rotate(-110deg);
          transform: rotate(-110deg);
}
.pan-item:hover .pan-info p a[data-v-175fbaac] {
  opacity: 1;
  -webkit-transform: translateX(0px) rotate(0deg);
          transform: translateX(0px) rotate(0deg);
}
