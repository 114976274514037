@charset "UTF-8";

/* fade */
.fade-enter-active,
.fade-leave-active {
  -webkit-transition: opacity 0.28s;
  transition: opacity 0.28s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

/* fade-transform */
.fade-transform-leave-active,
.fade-transform-enter-active {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.fade-transform-enter {
  opacity: 0;
  -webkit-transform: translateX(-30px);
          transform: translateX(-30px);
}

.fade-transform-leave-to {
  opacity: 0;
  -webkit-transform: translateX(30px);
          transform: translateX(30px);
}

/* breadcrumb transition */
.breadcrumb-enter-active,
.breadcrumb-leave-active {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.breadcrumb-enter,
.breadcrumb-leave-active {
  opacity: 0;
  -webkit-transform: translateX(20px);
          transform: translateX(20px);
}

.breadcrumb-move {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.breadcrumb-leave-active {
  position: absolute;
}

.el-breadcrumb__inner,
.el-breadcrumb__inner a {
  font-weight: 400 !important;
}

.el-upload input[type=file] {
  display: none !important;
}

.el-upload__input {
  display: none;
}

.cell .el-tag {
  margin-right: 0px;
}

.small-padding .cell {
  padding-left: 5px;
  padding-right: 5px;
}

.fixed-width .el-button--mini {
  padding: 7px 10px;
  width: 60px;
}

.status-col .cell {
  padding: 0 10px;
  text-align: center;
}
.status-col .cell .el-tag {
  margin-right: 0px;
}

.el-dialog {
  -webkit-transform: none;
          transform: none;
  left: 0;
  position: relative;
  margin: 0 auto;
}

.upload-container .el-upload {
  width: 100%;
}
.upload-container .el-upload .el-upload-dragger {
  width: 100%;
  height: 200px;
}

.el-dropdown-menu a {
  display: block;
}

.el-range-editor.el-input__inner {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

.el-drawer__wrapper {
  top: 150px;
  right: 16px;
}

.el-drawer__header {
  margin-bottom: 0px;
  padding: 10px 15px;
}

.el-checkbox__input.is-disabled + span.el-checkbox__label,
.el-radio__input.is-disabled + span.el-radio__label {
  color: #333333 !important;
}

.el-input.is-disabled .el-input__inner {
  background-color: #f8fafe !important;
  color: #333333 !important;
  border: 0 !important;
}

.el-tooltip__popper {
  max-width: 400px;
  display: inline-block;
  height: auto;
  max-height: 350px !important;
}

.scrollBar .el-table {
  overflow-x: auto;
}
.scrollBar .el-table .el-table__body-wrapper,
.scrollBar .el-table .el-table__header-wrapper,
.scrollBar .el-table .el-table__footer-wrapper {
  overflow: visible;
}
.scrollBar .el-table::after {
  position: relative !important;
}

.el-rate__icon {
  font-size: 50px;
}

.el-table .el-table__fixed {
  height: auto !important;
  bottom: 17px !important;
  background-color: #fff;
  border-left: 1px solid #dfe6ec;
  border-right: 1px solid #dfe6ec;
}

.el-select-dropdown__item {
  margin: 0 20px;
  padding: 0 !important;
}

.el-select-dropdown__item.selected {
  font-weight: normal;
}

.el-tree-node__content {
  margin-left: -5px !important;
}

.el-tree-node__content .is-leaf {
  margin-left: -10px !important;
}

.el-message-box__status + .el-message-box__message {
  overflow-wrap: anywhere;
}

.el-notification {
  width: 380px;
}
.el-notification .el-notification__title {
  word-break: break-all;
}
.el-notification .el-notification__content {
  max-height: 80vh;
  overflow-y: auto;
}

.el-popover {
  overflow: hidden;
  max-height: 100%;
}
.el-popover table {
  border-collapse: collapse;
  border-spacing: 0;
}
.el-popover th,
.el-popover td {
  padding: 0px 5px;
  height: 28px;
  border: 1px solid #dfe6ec !important;
  font-size: 13px;
  color: #333333;
  text-align: left;
}

.el-menu {
  background-color: #FFFFFF !important;
}

.el-menu-item {
  background-color: #FFFFFF !important;
}

.el-menu .el-menu--popup .el-menu--popup-right-start {
  background-color: #FFFFFF !important;
}

.el-date-range-picker__editors-wrap .el-date-range-picker__editor {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.el-date-range-picker__editors-wrap > span:nth-of-type(1) ::before {
  width: 100px;
  text-align: center;
  font-size: 12px;
}
.el-date-range-picker__editors-wrap > span:nth-of-type(2) {
  display: none !important;
}

.el-date-range-picker__time-header > span:nth-of-type(1) > span:nth-of-type(1) ::before {
  content: "开始日期";
}
.el-date-range-picker__time-header > span:nth-of-type(2)::before {
  content: "至" !important;
  color: #606266;
  font-size: 14px;
}
.el-date-range-picker__time-header > span:nth-of-type(3) > span:nth-of-type(1) ::before,
.el-date-range-picker__time-header .is-right > span:nth-of-type(1) ::before {
  content: "结束日期";
}

.el-icon-popper {
  z-index: 4000 !important;
}

#app .main-container {
  min-height: 100%;
  -webkit-transition: margin-left 0.28s;
  transition: margin-left 0.28s;
  margin-left: 210px;
  position: relative;
}
#app .sidebar-container {
  -webkit-transition: width 0.28s;
  transition: width 0.28s;
  width: 210px !important;
  background-color: #304156;
  height: 100%;
  position: fixed;
  font-size: 0px;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1001;
  overflow: hidden;
}
#app .sidebar-container .horizontal-collapse-transition {
  -webkit-transition: 0s width ease-in-out, 0s padding-left ease-in-out, 0s padding-right ease-in-out;
  transition: 0s width ease-in-out, 0s padding-left ease-in-out, 0s padding-right ease-in-out;
}
#app .sidebar-container .scrollbar-wrapper {
  overflow-x: hidden !important;
}
#app .sidebar-container .el-scrollbar__bar.is-vertical {
  right: 0px;
}
#app .sidebar-container .el-scrollbar {
  height: 100%;
}
#app .sidebar-container.has-logo .el-scrollbar {
  height: calc(100% - 50px);
}
#app .sidebar-container .is-horizontal {
  display: none;
}
#app .sidebar-container a {
  display: inline-block;
  width: 100%;
  overflow: hidden;
}
#app .sidebar-container .svg-icon {
  margin-right: 16px;
}
#app .sidebar-container .el-menu {
  border: none;
  height: 100%;
  width: 100% !important;
}
#app .sidebar-container .submenu-title-noDropdown,
#app .sidebar-container .el-submenu__title {
  height: 48px !important;
  line-height: 48px !important;
}
#app .sidebar-container .submenu-title-noDropdown:hover,
#app .sidebar-container .el-submenu__title:hover {
  background-color: #263445 !important;
}
#app .sidebar-container .is-active > .el-submenu__title {
  color: #f4f4f5 !important;
}
#app .sidebar-container .nest-menu .el-submenu > .el-submenu__title, #app .sidebar-container .el-submenu .el-menu-item {
  min-width: 210px !important;
  background-color: #1f2d3d !important;
}
#app .sidebar-container .nest-menu .el-submenu > .el-submenu__title:hover, #app .sidebar-container .el-submenu .el-menu-item:hover {
  background-color: #001528 !important;
}
#app .hideSidebar .sidebar-container {
  width: 54px !important;
}
#app .hideSidebar .main-container {
  margin-left: 54px;
}
#app .hideSidebar .submenu-title-noDropdown {
  padding: 0 !important;
  position: relative;
}
#app .hideSidebar .submenu-title-noDropdown .el-tooltip {
  padding: 0 !important;
}
#app .hideSidebar .submenu-title-noDropdown .el-tooltip .svg-icon {
  margin-left: 20px;
}
#app .hideSidebar .el-submenu {
  overflow: hidden;
}
#app .hideSidebar .el-submenu > .el-submenu__title {
  padding: 0 !important;
}
#app .hideSidebar .el-submenu > .el-submenu__title .svg-icon {
  margin-left: 20px;
}
#app .hideSidebar .el-submenu > .el-submenu__title .el-submenu__icon-arrow {
  display: none;
}
#app .hideSidebar .el-menu--collapse .el-submenu > .el-submenu__title > span {
  height: 0;
  width: 0;
  overflow: hidden;
  visibility: hidden;
  display: inline-block;
}
#app .el-menu--collapse .el-menu .el-submenu {
  min-width: 210px !important;
}
#app .mobile .main-container {
  margin-left: 0px;
}
#app .mobile .sidebar-container {
  -webkit-transition: -webkit-transform 0.28s;
  transition: -webkit-transform 0.28s;
  transition: transform 0.28s;
  transition: transform 0.28s, -webkit-transform 0.28s;
  width: 210px !important;
}
#app .mobile.hideSidebar .sidebar-container {
  pointer-events: none;
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
  -webkit-transform: translate3d(-210px, 0, 0);
          transform: translate3d(-210px, 0, 0);
}
#app .withoutAnimation .main-container,
#app .withoutAnimation .sidebar-container {
  -webkit-transition: none;
  transition: none;
}

.el-menu--vertical > .el-menu .svg-icon {
  margin-right: 16px;
}
.el-menu--vertical .nest-menu .el-submenu > .el-submenu__title,
.el-menu--vertical .el-menu-item {
  height: 45px !important;
  line-height: 45px !important;
}
.el-menu--vertical > .el-menu--popup {
  max-height: 100vh;
  overflow-y: auto;
}
.el-menu--vertical > .el-menu--popup::-webkit-scrollbar-track-piece {
  background: #d3dce6;
}
.el-menu--vertical > .el-menu--popup::-webkit-scrollbar {
  width: 6px;
}
.el-menu--vertical > .el-menu--popup::-webkit-scrollbar-thumb {
  background: #99a9bf;
  border-radius: 20px;
}

.blue-btn {
  background: #324157;
}
.blue-btn:hover {
  color: #324157;
}
.blue-btn:hover:before, .blue-btn:hover:after {
  background: #324157;
}

.light-blue-btn {
  background: #3A71A8;
}
.light-blue-btn:hover {
  color: #3A71A8;
}
.light-blue-btn:hover:before, .light-blue-btn:hover:after {
  background: #3A71A8;
}

.red-btn {
  background: #C03639;
}
.red-btn:hover {
  color: #C03639;
}
.red-btn:hover:before, .red-btn:hover:after {
  background: #C03639;
}

.pink-btn {
  background: #E65D6E;
}
.pink-btn:hover {
  color: #E65D6E;
}
.pink-btn:hover:before, .pink-btn:hover:after {
  background: #E65D6E;
}

.green-btn {
  background: #30B08F;
}
.green-btn:hover {
  color: #30B08F;
}
.green-btn:hover:before, .green-btn:hover:after {
  background: #30B08F;
}

.tiffany-btn {
  background: #4AB7BD;
}
.tiffany-btn:hover {
  color: #4AB7BD;
}
.tiffany-btn:hover:before, .tiffany-btn:hover:after {
  background: #4AB7BD;
}

.yellow-btn {
  background: #FEC171;
}
.yellow-btn:hover {
  color: #FEC171;
}
.yellow-btn:hover:before, .yellow-btn:hover:after {
  background: #FEC171;
}

.pan-btn {
  font-size: 14px;
  color: #fff;
  padding: 14px 36px;
  border-radius: 8px;
  border: none;
  outline: none;
  -webkit-transition: 600ms ease all;
  transition: 600ms ease all;
  position: relative;
  display: inline-block;
}
.pan-btn:hover {
  background: #fff;
}
.pan-btn:hover:before, .pan-btn:hover:after {
  width: 100%;
  -webkit-transition: 600ms ease all;
  transition: 600ms ease all;
}
.pan-btn:before, .pan-btn:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 2px;
  width: 0;
  -webkit-transition: 400ms ease all;
  transition: 400ms ease all;
}
.pan-btn::after {
  right: inherit;
  top: inherit;
  left: 0;
  bottom: 0;
}

.custom-button {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  background: #fff;
  color: #fff;
  -webkit-appearance: none;
  text-align: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  outline: 0;
  margin: 0;
  padding: 10px 15px;
  font-size: 14px;
  border-radius: 4px;
}

body {
  height: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;
}

label {
  font-weight: 700;
}

html {
  height: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

#app {
  height: 100%;
}

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
          box-sizing: inherit;
}

.no-padding {
  padding: 0px !important;
}

.padding-content {
  padding: 4px 0;
}

a:focus,
a:active {
  outline: none;
}

a,
a:focus,
a:hover {
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}

div:focus {
  outline: none;
}

.fr {
  float: right;
}

.fl {
  float: left;
}

.pr-5 {
  padding-right: 5px;
}

.pl-5 {
  padding-left: 5px;
}

.block {
  display: block;
}

.pointer {
  cursor: pointer;
}

.inlineBlock {
  display: block;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

aside {
  background: #eef1f6;
  padding: 8px 24px;
  margin-bottom: 20px;
  border-radius: 2px;
  display: block;
  line-height: 32px;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  color: #2c3e50;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
aside a {
  color: #337ab7;
  cursor: pointer;
}
aside a:hover {
  color: #20a0ff;
}

.app-container {
  padding: 20px;
}

.components-container {
  margin: 30px 50px;
  position: relative;
}

.pagination-container {
  margin-top: 30px;
}

.text-center {
  text-align: center;
}

.sub-navbar {
  height: 50px;
  line-height: 50px;
  position: relative;
  width: 100%;
  text-align: right;
  padding-right: 20px;
  -webkit-transition: 600ms ease position;
  transition: 600ms ease position;
  background: -webkit-gradient(linear, left top, right top, from(#20b6f9), color-stop(0%, #20b6f9), color-stop(100%, #2178f1), to(#2178f1));
  background: linear-gradient(90deg, #20b6f9 0%, #20b6f9 0%, #2178f1 100%, #2178f1 100%);
}
.sub-navbar .subtitle {
  font-size: 20px;
  color: #fff;
}
.sub-navbar.draft {
  background: #d0d0d0;
}
.sub-navbar.deleted {
  background: #d0d0d0;
}

.link-type,
.link-type:focus {
  color: #337ab7;
  cursor: pointer;
}
.link-type:hover,
.link-type:focus:hover {
  color: #20a0ff;
}

.filter-container {
  padding-bottom: 10px;
}
.filter-container .filter-item {
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 10px;
}

.multiselect {
  line-height: 16px;
}

.multiselect--active {
  z-index: 1000 !important;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

th,
td {
  padding: 0px 5px;
  height: 28px;
  font-size: 13px;
  color: #333333;
  text-align: left;
}