.app-cls[data-v-74af8ea3] {
  padding-top: 1px;
  background: #f5f5f5;
  padding-bottom: 10px;
}
.app-cls[data-v-74af8ea3]  .el-table {
  color: #333333;
  font-size: 13px;
}
.app-cls[data-v-74af8ea3]  label {
  font-weight: 500;
  font-size: 13px;
}
.app-cls[data-v-74af8ea3]  .el-button + .el-button {
  margin-left: 5px;
}
.app-cls[data-v-74af8ea3]  .el-card__body {
  padding: 10px;
}
.app-cls[data-v-74af8ea3] .el-col {
  min-height: 46px;
}
.app-cls[data-v-74af8ea3]  .el-table--small td {
  padding: 8px 0;
}
.app-cls .normal-btn-cls[data-v-74af8ea3]  .el-table--small td {
  padding: 5px 0;
}
.app-cls[data-v-74af8ea3]  .el-card__header {
  padding: 8px 10px;
}
.app-cls .form-item-cls[data-v-74af8ea3] {
  width: 100%;
  max-width: 320px;
}
.app-cls .header-cls[data-v-74af8ea3] {
  margin: 10px 15px 0px 15px;
  background: #f5f5f5;
}
.app-cls .table-cls[data-v-74af8ea3] {
  margin: 10px 15px 0px 15px;
  background: #f5f5f5;
}
.app-cls .table-cls[data-v-74af8ea3]  .el-table__body tr.current-row > td {
  background: #edf5ff !important;
}
.app-cls .table-cls[data-v-74af8ea3]  .el-table td {
  border-bottom: 1px solid #e9e9e9;
}
.app-cls .table-cls[data-v-74af8ea3]  thead .cell {
  color: #333333;
}
.app-cls .table-cls[data-v-74af8ea3]  .el-table__row .float-action-wrp {
  display: none;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
  -webkit-transition: right, 0.2s;
  transition: right, 0.2s;
}
.app-cls .table-cls[data-v-74af8ea3]  .el-table__row .float-action-wrp .float-action {
  display: block;
  padding: 0 6px 5px 6px;
  border-left: 1px solid #ebeef5;
  border-bottom: 1px solid #ebeef5;
  border-radius: 0 0 0 2px;
  white-space: nowrap;
  position: absolute;
  right: 0;
  bottom: -34px;
  background-color: #fff;
}
.app-cls .table-cls[data-v-74af8ea3]  .el-table__row .float-action-wrp .float-action .el-button + .el-button {
  margin-left: 5px;
}
.app-cls .table-cls[data-v-74af8ea3]  .el-table__row:hover td .float-action-wrp {
  display: block;
}
.app-cls .table-cls[data-v-74af8ea3]  .el-table__row:hover td .float-action-wrp .float-action {
  background-color: #f5f7fa;
  border-left: 1px solid #ebeef5;
  border-bottom: 1px solid #ebeef5;
}
.app-cls .table-cls[data-v-74af8ea3]  .el-table__row:last-child:not(:first-child) td .float-action-wrp .float-action {
  padding: 5px 6px 0 6px;
  top: -34px;
  bottom: auto;
  border-top: 1px solid #ebeef5;
  border-bottom: 0;
  border-radius: 2px 0 0 0;
}
.seachComStyle[data-v-74af8ea3] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.seachComStyle > div:first-child span[data-v-74af8ea3]:not(:first-child) {
  margin-left: 8px;
}
.seachComStyle > div:first-child span[data-v-74af8ea3] {
  font-size: 13px;
}
.seachComStyle > div[data-v-74af8ea3]:first-child .el-input,
.seachComStyle > div[data-v-74af8ea3]:first-child .el-select {
  max-width: 165px;
}
.seachComStyle > div:first-child button[data-v-74af8ea3] {
  margin-left: 8px;
}
.register[data-v-74af8ea3] {
  position: relative;
  width: 100%;
  height: 100%;
}
.register .from-information[data-v-74af8ea3] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -70%);
          transform: translate(-50%, -70%);
}
.register .from-information .password-warn[data-v-74af8ea3] {
  position: absolute;
  left: 80px;
  bottom: 74px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  font-size: 12px;
  color: #a9a9a9;
}
.register .from-information .tip-box[data-v-74af8ea3] {
  position: relative;
  width: 90%;
}
.register .from-information .tip-box .tip-text[data-v-74af8ea3] {
  position: absolute;
  left: 415px;
  top: 0;
  width: 100%;
  color: #808080;
  font-size: 12px;
}
.register .text-center[data-v-74af8ea3] {
  text-align: center;
}
.register .el-form[data-v-74af8ea3] {
  width: 550px;
  margin-top: 40px;
}
.register .el-form .apply-btn[data-v-74af8ea3] {
  width: 44%;
  margin-top: 30px;
  height: 40px;
}